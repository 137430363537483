import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    title: string;
    description: string;
    openGraphTwitterImageURL: string;
}

const HtmlTwitter: React.SFC<Props> = ({
    title,
    description,
    openGraphTwitterImageURL,
}) => (
    <Helmet>
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:domain" content="eclipse.guide" />
        <meta name="twitter:image" content={openGraphTwitterImageURL} />
        <meta name="twitter:image:alt" content={title} />
        <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
);

export default HtmlTwitter;
