import React, { useContext } from "react";
import { Content } from "./AppRoot";
import { MAIN_TITLE, DOMAIN_URL, APPLE_APP_ID } from "../config/env";
import HtmlTitle from "./helmet/HtmlTitle";
import HtmlDescription from "./helmet/HtmlDescription";
import HtmlLang from "./helmet/HtmlLang";
import HtmlCanonicalLink from "./helmet/HtmlCanonicalLink";
import HtmlSmartBanner from "./helmet/HtmlSmartBanner";
// import HtmlFacebookSDK from "./helmet/HtmlFacebookSDK";
import HtmlOpenGraph from "./helmet/HtmlOpenGraph";
import HtmlTwitter from "./helmet/HtmlTwitter";
import HtmlAlternateLinks from "./helmet/HtmlAlternateLinks";
import { getProductImagePath } from "../helpers/utils";

const MetaTags: React.FC = () => {
    const {
        animationSection: { subtitle },
        meta: { description },
        productSection: { description2 },
        code,
    } = useContext(Content);

    const title = `${MAIN_TITLE} - ${subtitle}`;
    const canonicalLink = `${DOMAIN_URL}/${code}`;

    const imageUrl = getProductImagePath(code);
    return (
        <>
            <HtmlTitle value={title} />
            <HtmlDescription value={description2} />
            <HtmlLang lang={code} />
            <HtmlCanonicalLink value={canonicalLink} />
            <HtmlSmartBanner appId={APPLE_APP_ID} />
            {/* <HtmlFacebookSDK /> */}
            <HtmlOpenGraph
                title={title}
                description={description}
                openGraphImageSize={"1200x630"}
                url={canonicalLink}
                openGraphImageURL={imageUrl}
            />
            <HtmlTwitter
                title={title}
                description={description}
                openGraphTwitterImageURL={imageUrl}
            />
            <HtmlAlternateLinks domainURL={DOMAIN_URL} />
        </>
    );
};

export default MetaTags;
